/**
 * This component renders the list of payment promotions
 */

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PaymentPromoCouponDetailsModal } from '@/components/PaymentPromoCouponDetailModal';
import { verifyPaymentOffer } from '@/redux/actions';
import CardPaymentBox from '@/components/CardPaymentBox';
import Modal from '@/components/Common/Modal';
import { PAYMENT_TYPE_CONSTANTS } from '@/utils/constants';
import { toastNotifyError } from '@/components/Common/Toast';
import {
  CartPromosContainer,
  PromosContainer,
} from 'src/components/CouponsAndVouchers/Common';
import { RootState } from 'src/redux/reducers';

const BankOffers = (props) => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    showPromoDetailsModal: false,
    promoDataToBeViewed: {},
    cardCouponData: {},
  });

  const isUserLoggedIn = useSelector((state: RootState) => state.userReducer.isLogin);

  const [showCardVerifyModal, setShowCardVerifyModal] = useState(false);

  const isCartPage = props?.isCartPage;

  const getAmtToAvailTheOffer = (minOrderValue, cartPayAmount) => {
    return minOrderValue - cartPayAmount;
  };
  const onApplyCoupon = (promoId) => {
    if (!isUserLoggedIn) {
      props?.showAuthModal?.();
      return;
    }
    const coupon = props?.paymentOffersList?.find((offer) => offer?.promo_id === promoId);
    if (getAmtToAvailTheOffer(coupon.min_order_price, props.cartData?.pay_amount) <= 0) {
      if (
        coupon.payment_method === PAYMENT_TYPE_CONSTANTS.DEBIT_CARD ||
        coupon.payment_method === PAYMENT_TYPE_CONSTANTS.CREDIT_CARD ||
        coupon.payment_method === PAYMENT_TYPE_CONSTANTS.CARD
      ) {
        setState({
          ...state,
          cardCouponData: coupon,
        });
        setShowCardVerifyModal(true);
      } else {
        dispatchVerifyCoupon(coupon);
      }
    }
  };

  const dispatchVerifyCoupon = (couponData) => {
    const dataToPost = {
      promo_code: couponData.promo_code,
      pay_amount: props.cartData?.pay_amount,
      promo_id: couponData.promo_id,
      payment_method: couponData.payment_method,
    };
    if (couponData.card_number) {
      dataToPost['card_number'] = couponData.card_number;
    }
    dispatch(
      verifyPaymentOffer(
        {
          ...props?.cartData,
          payment_offer: {
            ...dataToPost,
          },
        },
        (resp) => {
          if (resp.status) {
            props?.setSuccessPopupState({
              show: true,
              appliedPromoCode: couponData.promo_code,
            });
          } else {
            toastNotifyError(resp.message);
          }
        }
      )
    );
  };

  const togglePromoDetailsModal = (promoId) => {
    const promoData = props?.paymentOffersList?.find(
      (offer) => offer?.promo_id === promoId
    );
    setState((state) => ({
      ...state,
      showPromoDetailsModal: !state.showPromoDetailsModal,
      promoDataToBeViewed: promoData,
    }));
  };

  function onVerifyCard(cardData) {
    const dataToSend = {
      card_number: cardData.cNum,
      ...state.cardCouponData,
    };
    dispatchVerifyCoupon(dataToSend);
  }

  return (
    <div className="paymentPromotionMainContainer">
      {isCartPage ? (
        <CartPromosContainer
          promos={
            props.showAllPromos
              ? props.paymentOffersList
              : props.paymentOffersList.slice(0, 3)
          }
          onDetailClick={togglePromoDetailsModal}
          onApplyClick={onApplyCoupon}
          isStorePromos={false}
          cartPayAmount={props?.cartData?.pay_amount}
        />
      ) : (
        <PromosContainer
          promos={props.paymentOffersList}
          onPromoClick={togglePromoDetailsModal}
          customClasses={'tw-flex tw-flex-col md:tw-px-[24px] md:tw-py-[21px]'}
          primaryTextClasses="tw-text-[13px]/[18px] tw-font-medium md:tw-text-[16px]/[20px] md:tw-font-semibold"
          secondaryTextClasses="tw-text-[12px]/[20px] tw-font-normal md:tw-text-[15px]/[20px]"
          isStorePromos={false}
        />
      )}
      {state.showPromoDetailsModal && (
        <PaymentPromoCouponDetailsModal
          onClose={togglePromoDetailsModal}
          promoData={state.promoDataToBeViewed}
        ></PaymentPromoCouponDetailsModal>
      )}
      {showCardVerifyModal && (
        <Modal
          visible={true}
          height={200}
          animation={'slideUp'}
          onClose={() => setShowCardVerifyModal(false)}
          className="bottom-modal cardVerifyModal"
          customMaskStyles={{
            background: '#000000e6',
          }}
        >
          <span className="close-icon flex justify-center items-center">
            <img
              src="/assets/images/cancel_white.png"
              className="img h2 "
              onClick={() => setShowCardVerifyModal(false)}
            />
          </span>
          <div className="f4 b mb3">Verify Card</div>
          <CardPaymentBox
            isVerifyCard={true}
            onVerifyCard={onVerifyCard}
          ></CardPaymentBox>
        </Modal>
      )}
    </div>
  );
};

export default BankOffers;
